import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Button } from "../../../reusableComponents/Button"
import emailjs from "emailjs-com"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"
import { Link } from "gatsby"

gsap.registerPlugin(ScrollTrigger)

const NewsLetter = () => {
  const [newsLetterSent, setNewsLetterSent] = useState(false)

  let container = useRef(null)
  let content = useRef(null)
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    const h3 = content.children[0]
    const p = h3.nextSibling
    const form = p.nextSibling

    gsap.to(container, { duration: 0, css: { visibility: "visible" } })

    let tl = new gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: "20px 80%",
        end: "+=350px",
        scrub: 2,
      },
    })

    tl.from(
      [h3, p],
      {
        duration: 1,
        opacity: 0,
        y: 50,
        ease: "power3.inOut",
        delay: 0.2,
        stagger: 0.15,
      },
      "start"
    )
    tl.from(
      [form],
      {
        duration: 1,
        opacity: 0,
        y: 50,
        ease: "power3.inOut",
        delay: 0.8,
        stagger: 0.15,
      },
      "start"
    )
  }, [])

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  const sendEmail = async e => {
    // TODO: Create a reausable functional component for sending email
    if (!validateEmail(email)) {
      setError("Please enter a valid email address")
      return
    }
    setError("")

    e.preventDefault()
    e.persist()
    try {
      setIsSending(true)
      const data = await emailjs.sendForm(
        "service_psjv8k9",
        "template_qyu9e4j",
        e.target,
        "rIHXulH9lFawIJ1jI"
      )
      console.log(data)
      if (data.status === 200) {
        setNewsLetterSent(true)
        e.target.reset()
        setEmail("")
      }
    } catch (error) {
      console.log(error.text)
    } finally {
      setIsSending(false)
    }
  }

  const setNewsLetterIsSent = () => {
    setNewsLetterSent(false)
  }

  const data = useStaticQuery(graphql`
    query EmailBgQuery {
      allFile(
        filter: {
          ext: { regex: "/(jpg)|(png)|(jpeg)/" }
          name: { in: ["emailBg"] }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { cropFocus: CENTER }
                aspectRatio: 0.7
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  `)
  return (
    <EmailContainer ref={el => (container = el)}>
      {data.allFile.edges.map((image, key) => (
        <Images
          key={key}
          image={getImage(image.node.childImageSharp.gatsbyImageData)}
          alt="Writers Way Solutions Newsletter background"
        />
      ))}
      <EmailContent ref={el => (content = el)}>
        {!newsLetterSent ? (
          <>
            <h3>Subscribe to our newsletter</h3>
            <p>
              You will receive new information about our projects, deals,
              <br /> and other important information.
            </p>
            <form onSubmit={sendEmail}>
              <FormWrap>
                <label htmlFor="email">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    id="email"
                    name="from_email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </label>
                {error && <p style={{ color: "red" }}>{error}</p>}
                <ButtonContainer>
                  <Button
                    as="button"
                    type="submit"
                    round="true"
                    to="#"
                    css={`
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      @media screen and(max-width: 768px) {
                        width: 100%;
                        min-width: 350px;
                      }
                      @media screen and(max-width: 400px) {
                        width: 100%;
                        min-width: 250px;
                      }
                    `}
                  >
                    {isSending ? "Sending..." : "Subscribe"}
                  </Button>
                </ButtonContainer>
              </FormWrap>
            </form>
            <div
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.5rem;
                width: 50%;
                margin-top: .5rem;
              `}
            >
              <input
                type="checkbox"
                id="privacyPolicy"
                name="vehicle1"
                value=""
                css={`
                  margin-top: 0.2rem;
                  cursor: pointer;
                `}
              />
              <PrivacyText for="vehicle1">
                I agree to be contacted via text message by Writers Way
                Solutions. Message rates may apply. I can opt-out at any time by
                replying STOP. <NavLink to="/privacy">Privacy Policy</NavLink>
              </PrivacyText>
            </div>
          </>
        ) : (
          <div
            css={`
              display: flex;
              flex-direction: column;
              gap: 2rem;
              align-items: center;
              justify-content: center;
            `}
          >
            <h1>Thank you for Subscribing!</h1>
            <ButtonContainer>
              <Button
                onClick={setNewsLetterIsSent}
                as="button"
                round="true"
                to="#"
                css={`
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media screen and(max-width: 768px) {
                    width: 100%;
                    min-width: 350px;
                  }
                  @media screen and(max-width: 400px) {
                    width: 100%;
                    min-width: 250px;
                  }
                `}
              >
                Subscribe another email
              </Button>
            </ButtonContainer>
          </div>
        )}
      </EmailContent>
    </EmailContainer>
  )
}

export default NewsLetter

const EmailContainer = styled.div`
  visibility: hidden;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.9) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, transparent 100%),
    no-repeat center;
  background-size: cover;
  height: 450px;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const Images = styled(GatsbyImage)`
  background-size: cover;
  height: 450px;
  width: 100%;
  padding: 5rem calc((100vw - 1300px) / 2);
  z-index: -1;
`

const EmailContent = styled.div`
position: absolute;
display: flex;
flex-direction: column;
align-items:center;
z-index: 5;
 h3{
     text-align: center;
     margin-bottom: 1rem;
     font-size: clamp(1.7rem, 5vw, 2.3rem); 
     padding: 0 1rem;
     font-weight:700;
     color: ${props => props.theme.colors.accent5};
 }

 p{
     ${
       "" /* text-align:center;
     font-size: clamp(.7rem , 3vw ,1.2rem);
     padding:0 1rem;
     margin-bottom: 2rem;
     line-height:1.5;
     letter-spacing:.5px; */
     }
     ${"" /* font-family: 'Roboto', sans-serif; */}
     font-size: clamp(.7rem , 5vw ,.9rem);
      margin-bottom:1rem;
      padding:0 1rem;
      font-weight:400;
      text-align:center;
      line-height:1.5;
      color:${props => props.theme.colors.light2};
 }

 @media screen and (max-width: 568px) {
   br {
    display: none;
   }
 }

 form{
     z-index 10;
 }
`
const FormWrap = styled.div`
  display: flex;
  ${"" /* flex-direction: column; */}
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  input {
    padding: 1rem 1.5rem;
    outline: none;
    width: 350px;
    height: 40px;
    border-radius: 50px;
    border: none;
    margin-right: 1rem;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    input {
      margin-bottom: 0.2rem;
      width: 100%;
      margin-right: 0;
    }
  }
`
const ButtonContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`
const PrivacyText = styled.label`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #FFF;
  text-align: justify;
`

const NavLink = styled(Link)`
  font-size: 12px;
  letter-spacing: 1.3px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
  font-weight: 500;
  color: ${props => props.theme.colors.main1};
  cursor: pointer;
  text-decoration: underline;
`
