import * as React from "react"
import Seo from "../components/seo"
import Hero from "../components/sections/book-trailers/Hero"
import BookTrailers from "../components/sections/book-trailers/BookTrailers"
import Invite from "../components/sections/book-trailers/Invite"
import Follow from "../components/sections/book-trailers/Follow"
import NewsLetter from "../components/sections/home/newsLetter/NewsLetter"
import { Helmet } from "react-helmet"

const BookTrailerPage = () => (
  <>
    <Seo title="Book trailers" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Hero/>
    <BookTrailers />
    <Invite />
    <Follow />
    {/* <NewsLetter/> */}
  </>
)

export default BookTrailerPage
