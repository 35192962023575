import React from "react"
import styled from "styled-components"
import { Button } from "../../reusableComponents/Button"
import iconUp from "../../../assets/images/icons/arrows-up-icon.svg"
import iconDown from "../../../assets/images/icons/arrows-down-icon.svg"

function Hero() {
  return (
    <>
      <Container>
        <Section gap={"2rem"}>
          <Title>
            Make your book
            <br /> the next big thing
          </Title>
          <Description>
            Find the perfect combination of professional services for your book
            with our help. By publishing with us, you gain the years of
            experience and industry knowledge that we've built into our
            packages.
          </Description>
          <ButtonContainer>
            <Button round="true" to="/contact" dark>
              Get started
            </Button>
          </ButtonContainer>
        </Section>
        <Section>
          <MainVideoContainer>
            <IconUp src={iconUp} />
            <VideoWrapper>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/CSmIiZwAcqY?si=1u33h1ZYOgOA-TnQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </VideoWrapper>
            <IconDown src={iconDown} />
          </MainVideoContainer>
        </Section>
      </Container>
    </>
  )
}

export default Hero

const Container = styled.main`
  display: flex;
  gap: 3rem;
  padding: 4rem calc((100vw - 1200px) / 2);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
  background: #5890e1;
  box-sizing: border-box;
  margin-top: 1rem;

  @media screen and (max-width: 1249px) {
    padding-left: 1.5rem;
    padding-right: 5rem;
    box-sizing: border-box;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    flex-direction: column;
    padding-right: 1.5rem;
  }
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  width: 100%;
  height: 100%;

  @media screen and (max-width: 767px) {
    gap: 1rem;
  }
`

const Title = styled.h1`
  color: #fff;
  font-size: 70px;

  @media screen and (max-width: 767px) {
    font-size: 3rem;
  }
`

const Description = styled.p`
  color: hsl(0, 0%, 94%);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;

  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`

const MainVideoContainer = styled.div`
  position: relative;
  max-height: 475px; /* Adjust as necessary */
  min-height: 475px; /* Adjust as necessary */
  width: 100%;

  @media screen and (max-width: 767px) {
    height: 400px;
    max-height: 400px; /* Adjust as necessary */
    min-height: 400px;
  }
`

const VideoWrapper = styled.div`
  background: #d9d9d9;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
`
const ButtonContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
  margin-top: 1rem;
`

const IconUp = styled.img`
  position: absolute;
  z-index: 2;
  top: -3rem;
  right: -4rem;
  @media screen and (max-width: 767px) {
    display: none;
  }
`
const IconDown = styled.img`
  position: absolute;
  z-index: 2;
  bottom: -3rem;
  left: -4rem;
  @media screen and (max-width: 767px) {
    display: none;
  }
`
