import React from "react"
import styled from "styled-components"
import { Button } from "../../reusableComponents/Button"
import { bookTrailers } from "../../../data/bookTrailers"

function BookTrailers() {
  return (
    <>
      <Container>
        <TitleWrapper>
          <Title>Book Trailers</Title>
        </TitleWrapper>
        <Section>
          {bookTrailers.map((item, index) => {
            return (
              <Wrapper gap={"3rem"} ai={"center"}>
                <VideoContainer>
                  <InnerWrapper>
                    <iframe
                      src={item.iFrame}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </InnerWrapper>
                </VideoContainer>
                <Wrapper isColumn={true} gap={"1rem"} jc={"center"}>
                  <ContentTitle>{item.title}</ContentTitle>
                  <ContentDescription>
                    {item?.description ? item?.description : "N/A"}
                  </ContentDescription>
                  {item?.link && (
                    <ButtonContainer
                      href={item?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button round="true">Read More</Button>
                    </ButtonContainer>
                  )}
                </Wrapper>
              </Wrapper>
            )
          })}
        </Section>
      </Container>
    </>
  )
}

export default BookTrailers

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem calc((100vw - 1200px) / 2);
  background: #f2f0eb;
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  width: 100%;
  @media screen and (max-width: 1249px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.accent7};
  font-size: 3rem;
  @media screen and (max-width: 767px) {
    font-size: 2.5rem;
  }
`

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 281px;
  min-width: 510px;
  max-width: 510px;
  overflow: hidden;
  background: #d9d9d9;
  border-radius: 16px;

  @media screen and (max-width: 1249px) {
    max-height: 350px;
    min-width: 350px;
    max-width: 350px;
    width: 350px;
  }

  @media screen and (max-width: 567px) {
    min-width: unset;
    max-width: unset;
    max-height: 250px;
    width: 100%;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

const ButtonContainer = styled.a`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
  margin-top: 1rem;

  @media screen and (max-width: 1249px) {
    margin-top: 0;
  }
`

const Wrapper = styled.div`
  display: ${({ isGrid }) => (isGrid ? "grid" : "flex")};
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  gap: ${({ gap }) => (gap ? gap : "0")};
  grid-template-columns: 1fr 1fr;
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};

  @media screen and (max-width: 1249px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`

const ContentTitle = styled.h4`
  color: ${props => props.theme.colors.dark4};
  font-size: 2rem;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
`
const ContentDescription = styled.p`
  color: ${props => props.theme.colors.dark2};
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3; /* Number of lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`
