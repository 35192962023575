export const bookTrailers = [
  {
    iFrame: "https://www.youtube.com/embed/HkNadkoEC9g?si=QWZh6feaN6LzQoCr",
    title: "Selectively Lawless - By Asa Dunnington",
    link: "https://www.amazon.com/Selectively-Lawless-Emmett-American-Original-ebook/dp/B08VVGK8YH/ref=sr_1_1?crid=1X0E8L8BPCJ7K&dib=eyJ2IjoiMSJ9.Na-7L7Tbl3H4FMVVFSKEvg.lXUKc-2I1TdoX61tMzK6MDAmP-J8BFny8k1cyte-TY4&dib_tag=se&keywords=selectively+lawless&qid=1720805599&sprefix=Selectively+Lawless%2Caps%2C290&sr=8-1",
    description:
      "This is the story of the son of a sharecropping lay minister who, at age 14, walks off the Texas cotton field his family is working on and tells his brother he is never picking cotton again. For the next 18 months he travels halfway across the country and up the Pacific Coast honing his skills as a gambler. At the end of that time he returns to his family driving a new Buick Roadster with $18,000 in his pocket. The money bank rolls his bootlegging enterprise.",
  },
  {
    iFrame: "https://www.youtube.com/embed/Up9Rs8lKIfY?si=iMegWiSoDtqRGOQe",
    title: "Mantis Squad - Book Trailer by Gordon Bostic",
    link: "https://www.amazon.com/Mantis-Squad-Gordon-Bostic/dp/B0CJB17DJ3/ref=sr_1_1?crid=LTRUCPXHA1LO&dib=eyJ2IjoiMSJ9.vjiHXXcdcT2TzjG7qJwucTEKPBDGZVGrIYal1qTST8V0DerFih4q7L2WjCOt4HdH7uZ1vvz44_eQPAnXX4oC_l2dqPcs6voQOCqCdkxYy6ikIamtSCdmaNWQ5VgkskZZsYcI3VdHDjnUn-3UryPh9vzVlvQ7WD4qfK0eZC8y-VvrWzT-gv5_kZUw6GoTT7u8IHuwh_hW26rZDb1mwVn2V8DFuZlXPImMPz2T2JAuhEA7Jy_HYdWpiH8eoZDSaUJ2O8SOJ1Vrw5sdAI9BpLkqDoCgppaoxMHnZkufPRMuNOo.siXG-giTezGSBbpCBvEt_vhlIAsrRvDEvqxqMWoCgDA&dib_tag=se&keywords=mantis+squad&qid=1720806894&sprefix=mantis+squad%2Caps%2C370&sr=8-1",
    description:
      "In the gripping and enthralling poetic novel 'Mantis Squad,' a group of gifted children with extraordinary abilities become pawns in a dangerous government experiment. Their powers, concealed by their parents, are forcibly revealed when the government snatches them away for intensive training. As the story unfolds, the girls of the Mantis Squad face relentless challenges and struggle to trust one another in the harsh conditions of their training. But their journey is far from ordinary; it is a thrilling exploration of power, trust, and identity. Readers will find themselves irresistibly drawn into the girls' fight for freedom and survival, their individual character arcs and dynamic relationships creating a captivating narrative that leaves no room for rest. Within the electrifying pages of 'Mantis Squad,' the author Gordon Bostic expertly crafts a tale that will keep readers on the edge of their seats. ",
  },
  {
    iFrame: "https://www.youtube.com/embed/j1c5cJ1XSxk?si=03zD1PpnqdBoNKov",
    title: "The Chosen - Book Trailer by Gordon Bostic",
    link: "https://www.amazon.com/Chosen-Gordon-Bostic-ebook/dp/B0CVVK3LC1/ref=sr_1_1?crid=2YADJXZ1N6HOG&dib=eyJ2IjoiMSJ9.79hAJTUMguEKDI3xclhuKDzLNr5KIhJZKmuaqph3XRzGjHj071QN20LucGBJIEps.YR29nNSa1yTyUJrZv3JWtr4kirggBHn2I4Yad8tYgDk&dib_tag=se&keywords=the+chosen+gordon+bostic&qid=1720807040&sprefix=the+chosen+gordon+bosti%2Caps%2C265&sr=8-1",
    description:
      "In a serene American town where reality and the mystical intertwine, 'The Chosen' is a mesmerizing tale of love, magic, and human resilience. When a runaway named Rob disrupts the tranquil facade, the lives of the townsfolk are forever changed. Through well-crafted characters and poetic prose, this enchanting novel explores the extraordinary within the ordinary, blurring the boundaries between what is real and what is magical. As strange occurrences unfold, a hidden world of destiny and wonder emerges, captivating readers with suspense and intrigue",
  },
  {
    iFrame: "https://www.youtube.com/embed/f1cpiD6aW5A?si=xK5-xKZiJ56-dmIh",
    title: "Deception - Book Trailer by Sandra Kay Lee",
    link: "https://www.amazon.com/Deception-Chronicles-Bretts-Mountain-Book/dp/1524639559",
    description:
      "Benson Carver had one thing in mind, revenge. Dominguez Talley killed his brother, and he would prove it. 'I will escape the walls of this prison to destroy that man, and his daughter Katie will assist me. She will gladly help me when I tell her what really caused her mother's death,' he grinned as the warden counted his bribery money. Although he was Katie's bodyguard when she was young, he kidnapped her once before and he would do it, again. Nothing would stop him this time. Money is the root of all evil and he has plenty.",
  },
  {
    iFrame: "https://www.youtube.com/embed/H3zQcA0_GFs?si=eWvhq-vjruWa-rse",
    title: "The Labyrinth - Book Trailer by Gordon Bostic",
    link: "https://www.amazon.com/Labyrinth-Gordon-Bostic-ebook/dp/B0CVVCXD66/ref=sr_1_1?crid=21A7T0Q6FPJLL&dib=eyJ2IjoiMSJ9.82k-v0xcawJlXngb13VWBA.1dfcnbKAdwjOebf2gV8VerNkeZsRH53gU3ij2KnNvrs&dib_tag=se&keywords=the+labyrinth+gordon+bostic&qid=1720807299&s=books&sprefix=the+labyrinth+gordon+bosti%2Cstripbooks-intl-ship%2C259&sr=1-1",
    description:
      "In a world gripped by economic despair and uncertainty, Lee and his family teeter on the precipice of homelessness. Desperation fuels Lee's fateful decision to enter the enigmatic Labyrinth Challenge—a treacherous test promising a way out of poverty. But as he delves deeper into the labyrinth, he finds himself confronting a sinister world rife with traps, rumors, and an ever-growing sense of unease. The labyrinth itself becomes a character, shrouded in mystery and danger, enticing readers with its enigmatic secrets and keeping them on the edge of their seats. As Lee battles moral dilemmas and inner demons, he must navigate the labyrinth's complex web of deception and manipulation, while still balancing his duty to his family and his own survival.",
  },
  // {
  //   iFrame: "https://www.youtube.com/embed/rblMoZAbF_g?si=KqcJ75jpxDdB8gQk",
  //   title: "Romantic the promises",
  //   link: null,
  //   description: null,
  // },
  // {
  //   iFrame: "https://www.youtube.com/embed/IPaPuGMt9xg?si=PAWnsBhzC-cvKjsO",
  //   title: "War of land",
  //   link: null,
  //   description: null,
  // },
  // {
  //   iFrame: "https://www.youtube.com/embed/xkRvN7rdRSY?si=n7IfyGluZwd8RfGz",
  //   title: "Landlord",
  //   link: null,
  //   description: null,
  // },
  // {
  //   iFrame: "https://www.youtube.com/embed/893R5JNYWqI?si=uMEL7M_LPluRlXM_",
  //   title: "Alien Invasion",
  //   link: null,
  //   description: null,
  // },
]
