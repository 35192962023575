import React from "react"
import styled from "styled-components"
import { Button } from "../../reusableComponents/Button"
import fbIcon from "../../../assets/images/icons/fb-icon.svg"
import youtubeIcon from "../../../assets/images/icons/youtube-icon.svg"
import instagramIcon from "../../../assets/images/icons/insta-icon.svg"
import spotifyIcon from "../../../assets/images/icons/spotify-icon-4.svg"
import arrowRightIcon from "../../../assets/images/icons/arrow-right-icon.png"
import youtubeImage from "../../../assets/images/icons/youtube-image.jpg"
import fbImage from "../../../assets/images/icons/fb-image.jpg"
import spotifyImage from "../../../assets/images/icons/spotify-image.jpg"

function BookTrailers() {
  return (
    <>
      <Container>
        <TitleWrapper>
          <Title>Follow and be updated</Title>
        </TitleWrapper>
        <Section>
          <Wrapper gap={"2rem"} ai={"center"} mbGrid>
            <CardContainer
              href="https://www.youtube.com/@WritersWaySolutions."
              target="_blank"
              rel="noopener noreferrer"
            >
              <Wrapper ai={"center"} jc={"center"} height={"215px"}>
                <Image src={youtubeImage} />
                <Icon src={youtubeIcon} />
              </Wrapper>
              <ActionWrapper>
                <ActionLabel>Youtube</ActionLabel>
                <Icon src={arrowRightIcon} />
              </ActionWrapper>
            </CardContainer>

            <CardContainer
              href="https://www.facebook.com/profile.php?id=61555616772326"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Wrapper ai={"center"} jc={"center"} height={"215px"}>
                <Image src={fbImage} />
                <Icon src={fbIcon} />
              </Wrapper>
              <ActionWrapper>
                <ActionLabel>Facebook</ActionLabel>
                <Icon src={arrowRightIcon} />
              </ActionWrapper>
            </CardContainer>

            <CardContainer
              href="https://open.spotify.com/show/3Mg1BrXJApuM7Gk5a86dAV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Wrapper ai={"center"} jc={"center"} height={"215px"}>
                <Image src={spotifyImage} />
                <Icon src={spotifyIcon} />
              </Wrapper>
              <ActionWrapper>
                <ActionLabel>Spotify Podcast</ActionLabel>
                <Icon src={arrowRightIcon} />
              </ActionWrapper>
            </CardContainer>
          </Wrapper>
        </Section>
      </Container>
    </>
  )
}

export default BookTrailers

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 10rem calc((100vw - 1200px) / 2);
  /* background: #9691ff; */
  background: #fff;

  @media screen and (max-width: 1274px) {
    padding: 4rem 1.5rem;
    gap: 3rem;
  } ;
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  width: 100%;
`

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.accentSkyBlue};
  font-size: 3rem;

  @media screen and (max-width: 767px) {
    font-size: 2.5rem;
  } ;
`

const CardContainer = styled.a`
  position: relative;
  width: 100%;
  max-height: 295px;
  min-height: 295px;
  overflow: hidden;
  border-radius: 32px;
  background: #f2f0eb;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
`

const ButtonContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
  margin-top: 1rem;
`

const Wrapper = styled.div`
  display: ${({ isGrid }) => (isGrid ? "grid" : "flex")};
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  gap: ${({ gap }) => (gap ? gap : "0")};
  grid-template-columns: 1fr 1fr;
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "unset")};
  position: relative;

  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  @media screen and (max-width: 1274px) {
    display: ${({ mbGrid }) => (mbGrid ? "grid" : "flex")};
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  } ;
`

const ActionWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  cursor: pointer;
`

const ContentTitle = styled.span`
  color: ${props => props.theme.colors.light3};
  font-size: 1.5rem;
`
const ContentDescription = styled.p`
  color: ${props => props.theme.colors.light2};
  font-size: 14px;
  line-height: 20px;
`

const ActionLabel = styled.p`
  color: ${props => props.theme.colors.dark4};
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
`

const Icon = styled.img`
  position: relative;
  z-index: 3;
`
const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`
