import React from "react"
import styled from "styled-components"
import { Button } from "../../reusableComponents/Button"
import toolsIcon from "../../../assets/images/icons/tools-icon.svg"

function BookTrailers() {
  return (
    <>
      <Container>
        <Section>
          <Wrapper gap={"3rem"} ai={"center"}>
            <CardContainer>
              <ContentTitle>Want to publish your book?</ContentTitle>
              <ContentDescription>
                Publishing your book is more than sharing a story—it's leaving a
                lasting mark on the world. It fulfills dreams, inspires, and
                connects with readers, sparking conversations and challenging
                beliefs. Whether fiction, non-fiction, or poetry, your work
                deserves to be cherished. Take the leap, believe in your story,
                and share it with readers eager to discover new voices. Your
                journey as an author begins with publishing—embrace it and watch
                your words resonate widely.
              </ContentDescription>
              <Wrapper ai={"center"} jc={"space-between"}>
                <ButtonContainer>
                  <Button primary round="true" to="/publishing-services">
                    View Publishing Services
                  </Button>
                </ButtonContainer>
                <ToolsIcon src={toolsIcon} />
              </Wrapper>
            </CardContainer>
            <CardContainer>
              <ContentTitle>Want to market your book?</ContentTitle>
              <ContentDescription>
                Marketing your book is essential for maximizing its reach and
                impact. It boosts visibility, sales, and establishes your
                authority in your genre. Utilize social media, email
                newsletters, reviews, and influencer collaborations to engage
                directly with your audience. This proactive strategy not only
                increases sales but also leads to speaking engagements, media
                exposure, and future writing opportunities. Embrace marketing to
                amplify your voice, connect authentically with readers, and
                leave a lasting impression in the literary world.
              </ContentDescription>
              <Wrapper ai={"center"} jc={"space-between"} width={"100%"} mbRow>
                <ButtonContainer>
                  <Button primary round="true" to="/marketing-services">
                    View Marketing Services
                  </Button>
                </ButtonContainer>
                <ToolsIcon src={toolsIcon} />
              </Wrapper>
            </CardContainer>
          </Wrapper>
        </Section>
      </Container>
    </>
  )
}

export default BookTrailers

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 10rem calc((100vw - 1200px) / 2);
  background: #5890e1;
  /* background: #26008d; */
  /* background: #AEEEFF; */

  @media screen and (max-width: 1249px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  width: 100%;
`

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.accent7};
  font-size: 3rem;
`

const CardContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 410px;
  min-height: 410px;
  overflow: hidden;
  border-radius: 32px;
  /* border: 2px solid #5890e1; */
  /* border: 2px solid #9691ff; */
  border: 2px solid #75b3ef;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.5rem;
  box-sizing: border-box;
  gap: 1rem;

  @media screen and (max-width: 1274px) {
    max-height: 455px;
    min-height: 455px;
  }

  @media screen and (max-width: 967px) {
    min-height: unset;
    max-height: unset;
    height: auto;
    padding: 1.8rem;
  }
`

const ButtonContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
  margin-top: 1rem;

  @media screen and (max-width: 967px) {
    margin-top: 0;
  }
  @media screen and (max-width: 471px) {
   width: 100%;
  }
`

const Wrapper = styled.div`
  display: ${({ isGrid }) => (isGrid ? "grid" : "flex")};
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  gap: ${({ gap }) => (gap ? gap : "0")};
  grid-template-columns: 1fr 1fr;
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  width: ${({ width }) => (width ? width : "100%")};

  @media screen and (max-width: 1249px) {
    gap: 1.5rem;
  }

  @media screen and (max-width: 967px) {
    flex-direction: ${({ mbRow }) => (mbRow ? "row" : "column")};
    align-items: center;
  }
`

const ContentTitle = styled.span`
  color: ${props => props.theme.colors.light3};
  /* color: #3D3F3F; */
  font-size: 1.5rem;
  font-weight: 700;
`
const ContentDescription = styled.p`
  color: ${props => props.theme.colors.light2};
  /* color: #69878F; */
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-align: justify;
`

const ToolsIcon = styled.img`
  @media screen and (max-width: 471px) {
    display: none;
  }
`
